<template>
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="12">
        <div class="header-title">儀表板</div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content">
    <div class="app-dashboard-page">
      
    </div>
  </a-layout-content>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    return {}
  },
  methods: {

  }
})
</script>